<template>
  <main>
    <head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    </head>
    <div class="about-detail" ref="aboutDetail" v-if="currentContentVisibility=='middle'">
      <abouts-wrapper2
        @close-about-detail="closeAboutDetail"
        :about-data="aboutData"
      />
    </div>
    <div class="bg" :style="{
      backgroundImage: innerWidth > 768 || aboutData.video && aboutData.video.length != 0
        ?`url(${aboutData.image})`:`url(${aboutData.imagemobile})`,
      backgroundColor: innerWidth > 768 ? '':aboutData.background ,
      backgroundSize: innerWidth > 768 ||aboutData.video&&aboutData.video.length!=0 ? 'cover' : 'contain',
      backgroundPosition: innerWidth > 768 ? 'top center' : 'center',
    }">
      <!-- <pre>{{ innerWidth }}</pre> -->
      <button class="btn-close-content fixed" :style="{backgroundColor:`#fff`, borderColor:aboutData.color,color:aboutData.color}" @click="closeAboutContentFullDetail" v-if="currentContentVisibility=='full'">
        <span class="close" :style="{backgroundColor:aboutData.color, color:`#fff`}">&times;</span>
        <span class="btn-back">{{ $t("app.back") }}</span>
      </button>
      <div class="video-overlay" @mouseover="showButton" @mouseleave="hideButton" v-if="aboutData.video&&aboutData.video.length!=0">
        <video ref="video" :src="aboutData.video&&aboutData.video[0]" :controls="innerWidth <= 768" :volume="volume" autoplay loop muted></video>
        <button v-if="showPlayPauseButton" class="play-pause-button" @click="togglePlayPause">
          <i v-if="isPlaying" class="fas fa-pause"></i>
          <i v-else class="fas fa-play"></i>
        </button>
        <div v-if="showPlayPauseButton" class="volume-controls">
          <button @click="toggleMute">
            <i v-if="isMuted" class="fas fa-volume-mute"></i>
            <i v-else class="fas fa-volume-up"></i>
          </button>
          <input type="range" min="0" max="1" step="0.1" v-model="volume" @input="setVolume" />
        </div>
      </div>
    </div>
    <div class="list-about" v-if="currentContentVisibility=='middle'">
      <abouts-wrapper1
        @close-about-list="closeAboutList"
        @close-about-contentfull-list="closeAboutContentFullList"
        :about-data="aboutData"
        :about-menu="aboutMenu"
      />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import TweenLite from 'gsap/TweenLite'
import AboutListWrapper from './AboutListWrapper'
import AboutDescriptionWrapper from './AboutDescriptionWrapper'
let flexData

export default {
  name: 'About',
  components: {
    'abouts-wrapper1': AboutListWrapper,
    'abouts-wrapper2': AboutDescriptionWrapper
  },
  props: {
    aboutData: {
      type: Object,
      required: true
    },
    aboutMenu: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isPlaying: true,
      showPlayPauseButton: false,
      volume: 1,
      isMuted: true,
      nameMenu: 'Sobre Nós',
      innerWidth: window.innerWidth
    }
  },
  async mounted () {
    window.addEventListener('resize', this.handleResize) // Adiciona o evento resize ao carregar o componente
    await this.$store.dispatch('about/setRouteName', this.$route.name)
    if (window.screen.width > 768) {
      flexData = 0.4
    } else {
      flexData = 4
    }
    TweenLite.to('.list-about', 1, { flex: flexData })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize) // Remove o evento resize ao destruir o componente
  },
  computed: {
  /*     innerWidth () {
        return window.innerWidth
      }, */
    ...mapGetters('about', ['currentSettedBackgrond', 'currentContentVisibility'])
  },
  methods: {
    handleResize () {
      this.innerWidth = window.innerWidth// Atualiza a largura da janela sempre que for redimensionada
    },
    togglePlayPause () {
      const video = this.$refs.video
      if (video.paused) {
        video.play()
        this.isPlaying = true
      } else {
        video.pause()
        this.isPlaying = false
      }
    },
    onPlay () {
      this.isPlaying = true
    },
    onPause () {
      this.isPlaying = false
    },
    showButton () {
      this.showPlayPauseButton = true
    },
    hideButton () {
      this.showPlayPauseButton = false
    },
    setVolume () {
      const video = this.$refs.video
      video.volume = this.volume
      this.isMuted = this.volume === 0
    },
    toggleMute () {
      const video = this.$refs.video
      if (this.isMuted) {
        video.volume = this.volume
        video.muted = false
        this.isMuted = false
      } else {
        this.volume = video.volume
        video.volume = 0
        video.muted = true
        this.isMuted = true
      }
    },
    async closeAboutList () {
      if (window.screen.width > 768) {
        flexData = { flex: 1 }
      } else {
        flexData = { flex: 2 }
      }
      await this.$store.dispatch('about/setRouteName', 'logobranco')
      TweenLite.to('.list-about', 0.4, { flex: 0 })
      TweenLite.to('.about-detail', 0.4, { ...flexData, filter: 'blur(0px)' })
      document.querySelector('.about-detail').scrollTo(0, 0)
      this.$root.$emit('hideLogo')
      this.$root.$emit('hideLogox')
    },
    async closeAboutDetail () {
      if (window.screen.width > 768) {
        flexData = 0.4
      } else {
        flexData = 4
      }
      await this.$store.dispatch('about/setRouteName', 'About')
      await this.$store.dispatch('about/setContentVisibility', 'middle')
      TweenLite.to('.about-detail', 0.4, { filter: 'blur(1px)', flex: 0 })
      TweenLite.to('.list-about', 0.4, { flex: flexData })
      this.$root.$emit('showLogo')
      this.$root.$emit('showLogox')
    },
    async closeAboutContentFullList () {
      this.$refs.aboutDetail.removeAttribute('style')
      await this.$store.dispatch('about/setRouteName', 'logobranco')
      await this.$store.dispatch('about/setContentVisibility', 'full')
      TweenLite.to('.about-detail', 0.4)
      /* this.$root.$emit('showLogo')
      this.$root.$emit('showLogox') */
      this.$root.$emit('hideLogo')
      this.$root.$emit('hideLogox')
    },
    async closeAboutContentFullDetail () {
      await this.$store.dispatch('about/setRouteName', 'About')
      await this.$store.dispatch('about/setContentVisibility', 'middle')
      TweenLite.to('.about-detail', 0.4)
      TweenLite.to('.list-about', 0.4, { flex: flexData })
      this.$router.push({
        name: 'About',
        params: {
          about: this.nameMenu.toLowerCase().replace(/\s+/g, '-'),
          menu: this.nameMenu
        }
      })

      this.$root.$emit('showLogo')
      this.$root.$emit('showLogox')
    }
  }
}
</script>
<style scoped>
main {
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;
}
.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  transition: background 0.26s ease-in;
}

.btn-close-content {
    margin: 25px 0;
    border: 1px solid #000;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    width: 130px;
    height: 32px;
    outline: none;
    position: fixed;
    bottom: 10px;
    cursor: pointer;
    flex-direction: row-reverse;
    img {
      width: 20px;
      height: 20px;
    }
    .close {
      margin-top: -2px !important;
    }
    span {
      font-size: 16px;
      padding-right: 15px;
    }
  }
.video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90% !important;
  height: 60% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.video-overlay video {
  width: 100%;
  height: 100%;
  opacity: 8;
  pointer-events: auto;
}

.play-pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  pointer-events: auto;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.play-pause-button i {
  pointer-events: none;
}

.video-overlay:hover .play-pause-button {
  opacity: 1;
}
.volume-controls {
  position: absolute;
  bottom: 10px; /* Distância do fundo */
  right: 10px;  /* Distância da direita */
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);
}

.volume-controls button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  margin-right: 5px;
  pointer-events: auto;
}

.volume-controls input[type="range"] {
  width: 100px;
  pointer-events: auto;
}

.volume-controls input[type="range"] {
  -webkit-appearance: none;
  width: 100px;
  height: 5px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
}

.volume-controls input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e0112c;
  cursor: pointer;
}

.volume-controls input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e0112c;
  cursor: pointer;
}

.volume-controls input[type="range"]::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e0112c;
  cursor: pointer;
}
.list-about {
  flex: 0; /* flex: .4; */
  overflow: hidden;
}

.about-detail {
  flex: 0; /* flex: .4; */
  overflow: hidden;
  filter: blur(2px);
}

@media (max-width: 432px) {
  .list-about {
    flex: 0; /* flex: .4; */
    overflow: overlay;
  }
  .bg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    height: 100vh;
    background-size: contain;
    /* background-position: center; */
    background-repeat: no-repeat;
    transition: background 0.26s ease-in;
  }
  .video-overlay{
    width: 100% !important;
    height: 100% !important;
  }
  .play-pause-button{
    display:none;
  }
  .volume-controls {
    display: none;
    bottom: 20px;
    right: 20px;
  }
}

@media (min-width: 576px) {
  .volume-controls {
    bottom: 15px;
    right: 15px;
  }
}
@media (min-width: 768px) {
  main {
    flex-direction: row-reverse;
  }
  .volume-controls {
    bottom: 20px;
    right: 20px;
  }
}
@media (min-width: 992px) {
  .video-overlay {
    width: 60%;
    height: 60%;
  }
  .volume-controls {
    bottom: 25px;
    right: 25px;
  }
}

@media (min-width: 1200px) {
  .video-overlay {
    width: 50%;
    height: 50%;
  }
  .volume-controls {
    bottom: 30px;
    right: 60px;
  }
}

</style>
